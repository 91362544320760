
.imageContainer {
	display: flex;
	padding: 2px;
	transition: .3s;
	&__mainImage {
			display: flex;
			flex-direction: column;
			padding: 0 3em;
			width: 100%;
			

		}
	&__thumb {
		position: relative;
	}
	&__image {
		display: block;
		width: 100%;
		transition: .3s;
		border-radius: 10px;
		box-shadow: 2px 14px 38px -2px rgba(0,0,0,0.34)

	}
}


div.gallery img {
  width: 100%;
  height: auto;
  border-radius: 10px;


}
div.gallery{
	img{
	padding:5px;	
	box-shadow: inset 2px 14px 38px -2px rgba(0,0,0,0.1);

	}
}

div.desc {
  padding: 15px;

  text-align: center;
}