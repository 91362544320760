:root {
  --surface-color: rgba(255, 255, 255, 0.9);
  --curve: 50;
}

.cards__team {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3vw;
  margin: 7vw 5vw;
  padding: 0;
  list-style-type: none;
  margin-bottom: 7em;
}

.card__team {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 0.1px);
  overflow: hidden;
  text-decoration: none;
}

.card__team__title {
  font-size: 1.1em;
}
.card__team__status {
  font-size: 0.8em;
}
.card__team__description {
  font-size: 0.8em;
}

.card__team__image {
  width: 100%;
  height: auto;
}

.card__team__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card__team:hover .card__team__overlay {
  transform: translateY(0);
}

.card__team__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em 1em 0em 1em;
  background-color: var(--surface-color);
  transform: translateY(-99%);
  transition: 0.2s ease-in-out;
}

.card__team:hover .card__team__header {
  transform: translateY(0);
}

.card__team__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__team__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #ccb78f;
}
