@import "bootstrap/bootstrap";
@import "bootstrap/variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300&family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate&display=swap");

$font-primary: "monteserat", Arial, sans-serif;
$font-secondary: "Montserrat", Arial, sans-serif;
$heading-font: "josefin sans", Arial, sans-serif;

$white: #fff;
$black: #000000;
// $darken: #3a4348;

$primary: #ccb78f;
$secondary: #fffb37;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition) {
  -moz-transition: all $transition ease;
  -o-transition: all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: all $transition ease;
  transition: all $transition ease;
}

@mixin translateX($translatex) {
  -moz-transform: translateX($translatex);
  -webkit-transform: translateX($translatex);
  -ms-transform: translateX($translatex);
  -o-transform: translateX($translatex);
  transform: translateX($translatex);
}

body {
  font-family: $font-primary !important;
  background: #dddddd;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  // background-image: url("../images/bg_body12.jpg");
  // background-repeat: no-repeat;
  // background-size:cover;
  // background-image: url("../images/bg_body4.jpg");
  color: lighten($black, 30%);
  &.offcanvas {
    overflow-x: hidden;
    #colorlib-aside {
      @include translateX(0);
      width: 220px;
      background: $white;
      z-index: 999;
      position: fixed;
    }
    #colorlib-main {
      top: 0;
      @include translateX(220px);
    }
    .colorlib-nav-toggle {
      top: 0;
      @include translateX(160px);
    }
  }
}
a {
  transition: 0.3s all ease;
  color: $primary;
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary;
    outline: none !important;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.5;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: $heading-font;
}

#colorlib-page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#colorlib-aside {
  padding-top: 3em;
  padding-bottom: 40px;
  padding-left: 3em;
  padding-right: 3em;
  width: 22%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  z-index: 1001;

  @include transition(0.5s);
  @include media-breakpoint-up(lg) {
    padding-left: 1.5em;
    width: 15%;
  }
  @include media-breakpoint-down(md) {
    width: 270px;
    @include translateX(-270px);
    padding-top: 2em;
    background: rgba(0, 0, 0, 0.04);
  }
  #colorlib-logo {
    margin-bottom: 0.5em;
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 35px;
    line-height: 1;
    padding-left: 0em;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 30px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      font-size: 25px;
    }
    a {
      display: inline-block;
      color: $primary;
      position: relative;
      border: 1px solid $primary;
      padding: 8px 6px 0 6px;
    }
  }
  #madaviary-footer {
    font-size: 10px;
    line-height: 1;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 10px;
      line-height: 1.5;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      font-size: 8px;
      line-height: 2;
    }
  }
  #colorlib-main-menu {
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0 0 0 0;
        padding: 0;
        list-style: none;
        font-weight: 500;
        line-height: 1.8;
        @include media-breakpoint-up(md) {
          font-size: 1.8vw;
        }
        @include media-breakpoint-down(md) {
          font-size: 1.85vw;
        }
        @include media-breakpoint-down(sm) {
          font-size: 6vw;
        }
        a {
          color: rgba(0, 0, 0, 1);
          text-decoration: none;
          position: relative;
          padding: 10px 0;
          font-family: $font-primary;
          @include transition(0.3s);
          &:after {
            content: "";
            position: absolute;
            height: 2px;
            bottom: 7px;
            left: 0;
            right: 0;
            background-color: $primary;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          }
          &:hover {
            text-decoration: none;
            color: rgba(0, 0, 0, 1);

            &:after {
              visibility: visible;
              -webkit-transform: scaleX(1);
              -moz-transform: scaleX(1);
              -ms-transform: scaleX(1);
              -o-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
        }
        &.colorlib-active {
          a {
            color: $primary;
            &:after {
              visibility: visible;
              -webkit-transform: scaleX(1);
              -moz-transform: scaleX(1);
              -ms-transform: scaleX(1);
              -o-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
        }
      }
    }
    p.social {
      margin-top: 30px;
      letter-spacing: 2px;
      font-size: 17px;
      span {
        display: block;
        margin-top: 20px;
        a {
          color: $black;
        }
      }
    }
  }
  .colorlib-footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    padding: 0 1.5em;
    font-size: 17px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    a {
      color: $black;
    }
    span {
      display: block;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        a {
          width: 40px;
          height: 40px;
          color: rgba(0, 0, 0, 1);
          padding: 4px;
          font-size: 18px;
          @include border-radius(50%);
          background: darken($primary, 5%);
          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
            outline: none;
            color: $black;
            background: $white;
          }
        }
      }
    }
    .colorlib-subscribe-form {
      .form-group {
        position: relative;
        margin-bottom: 0;
        @include border-radius(0);
        input {
          padding-right: 40px;
          background: $white !important;
          border: 1px solid lighten($primary, 20%) !important;
          color: rgba(0, 0, 0, 0.4) !important;
          font-size: 14px;
          @include border-radius(0);
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(0, 0, 0, 0.4) !important;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(0, 0, 0, 0.4) !important;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba(0, 0, 0, 0.4) !important;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(0, 0, 0, 0.4) !important;
          }
        }
        .form-control {
          border: 1px solid rgba(255, 255, 255, 0.2);
          font-weight: 400;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

#colorlib-main {
  width: 100%;
  float: right;
  @include transition(0.5s);
  // @include media-breakpoint-up(lg){
  // 	width: 78%;
  // }
}

// Burger Menu
.colorlib-nav-toggle {
  cursor: pointer;
  text-decoration: none;
  &.active i {
    &::before,
    &::after {
      background: $black;
    }
  }
  &.dark {
    &.active i {
      &::before,
      &::after {
        background: $black;
      }
    }
  }
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-bottom: none !important;
  }
  i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 2px;
    color: $black;
    font: bold 14px/0.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: $black;
    transition: all 0.2s ease-out;
    &::before,
    &::after {
      content: "";
      width: 30px;
      height: 2px;
      background: $black;
      position: absolute;
      left: 0;
      @include transition(0.2s);
    }
  }
  &.dark {
    i {
      position: relative;
      color: $black;
      background: $black;
      transition: all 0.2s ease-out;
      &::before,
      &::after {
        background: $black;
        @include transition(0.2s);
      }
    }
  }
}

.colorlib-nav-toggle i::before {
  top: -7px;
}
.colorlib-nav-toggle i::after {
  bottom: -7px;
}
.colorlib-nav-toggle:hover i::before {
  top: -10px;
}
.colorlib-nav-toggle:hover i::after {
  bottom: -10px;
}
.colorlib-nav-toggle.active i {
  background: transparent;
}
.colorlib-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.colorlib-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.colorlib-nav-toggle {
  position: fixed;
  left: -12px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 20px;
  @include transition(0.5s);
  @include media-breakpoint-down(md) {
    opacity: 1;
    visibility: visible;
  }
}

// USED IN PROJECT
.ftco-intro {
  text-align: justify !important;
  .hero-title {
    font-size: 5vw;
    font-weight: 700;
    line-height: 1;
    display: block;

    @include media-breakpoint-down(sm) {
      font-size: 14vw;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 60px;
  }
  .hero-text {
    font-size: 1vw;
    line-height: 1.5;
    letter-spacing: 1.5px;
    @include media-breakpoint-down(md) {
      font-size: 1.5vw;
    }
    @include media-breakpoint-down(sm) {
      font-size: 4.5vw;
      letter-spacing: 1px;
    }
  }
}
// USED IN PROJECT UP

//HERO-WRAP
.hero-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  .author-info,
  .author-image {
    width: 50%;
    @include media-breakpoint-down(sm) {
      width: 30%;
    }
  }
  .author-info {
    @include media-breakpoint-down(sm) {
      width: 70%;
    }

    overflow-y: scroll;
    h3.signature {
    }
  }
  .author-image {
    .author-img {
      width: 100%;
    }
  }
  .text {
    position: relative;
  }
  .text {
    z-index: 1;
    font-size: 18px;
    font-weight: 500;
    .img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      @include border-radius(50%);
    }
    .subheading {
      font-size: 24px;
      color: lighten($black, 75%);
      font-weight: 400;
    }
    h1 {
      font-weight: 500;
      font-size: 30px;
      color: $black;
      text-transform: uppercase;
      span {
        color: $primary;
      }
      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
    .btn-custom {
      font-weight: 400;
      color: lighten($black, 70%);
      border-bottom: 2px solid lighten($black, 70%);
    }
  }
}

.video-hero {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  z-index: 0;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    background: $black;
  }
  .text {
    h1 {
      font-size: 4vw;
      margin-bottom: -6vw;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      color: $white;
      @include media-breakpoint-down(md) {
        font-size: 6vw;
      }
      @include media-breakpoint-down(sm) {
        font-size: 10vw;
      }
    }
    @include media-breakpoint-down(lg) {
      margin-top: -4vw;
    }
  }
}

//PHOTOGRAPHY
.photography-entry {
  height: 250px;
  display: block;
  z-index: 0;
  position: relative;
  margin-bottom: 30px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: $black;
    opacity: 0;
    @include transition(0.3s);
  }
  .text {
    opacity: 0;
    @include transition(0.3s);
    h3 {
      color: $white;
      font-size: 20px;
    }
    span.tag {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  &:hover,
  &:focus {
    .overlay {
      opacity: 0.7;
    }
    .text {
      opacity: 1;
    }
  }
}

//
.btn {
  cursor: pointer;
  @include border-radius(30px);
  box-shadow: none !important;
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
  &.btn-primary {
    background: $primary;
    border: 1px solid $primary;
    color: $white;
    &:hover {
      border: 1px solid $primary;
      background: transparent;
      color: $primary;
    }
    &.btn-outline-primary {
      border: 1px solid $primary;
      background: transparent;
      color: $primary;
      &:hover {
        border: 1px solid transparent;
        background: $primary;
        color: $white;
      }
    }
  }
  &.btn-white {
    background: $white;
    border: 1px solid $white;
    color: $black;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $white;
      color: $primary;
    }
    &.btn-outline-white {
      border-color: rgba($white, 0.8);
      background: none;
      @include border-radius(30px);
      border-width: 1px;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        background: $white;
        border-color: $white;
        color: $primary;
      }
    }
  }
}

.ftco-bread {
  padding: 3.5em 1.8em !important;
  .breadcrumbs {
    font-size: 13px;
    margin-bottom: 0;
    z-index: 99;
    span {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 1);
      a {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .bread {
    color: grey;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 4vw;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    line-height: 1;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      width: 100%;
      background: grey;
      content: "";
    }
  }
}

//SERVICES
.services {
  width: 100%;
  @include transition(0.3s);
  @include border-radius(1px);

  .icon {
    line-height: 1.3;
    width: 100px;
    height: 100px;
    background: lighten(#b7a27e, 16%);
    @include border-radius(25%);
    span {
      font-size: 54px;
      color: $black;
    }
    img {
      height: 100px;
      width: 100px;
    }
  }
  .media-body {
    .heading {
      font-size: 1.25vw;
      line-height: 1.2;
      @include media-breakpoint-down(md) {
        font-size: 2.4vw;
      }
      @include media-breakpoint-down(sm) {
        font-size: 6vw;
      }
    }
    .serviceText {
      text-align: justify !important;
      font-size: 1vw;
      letter-spacing: 0.5px;
      line-height: 1.5;
      @include media-breakpoint-down(md) {
        font-size: 1.3vw;
      }
      @include media-breakpoint-down(sm) {
        font-size: 3vw;
        margin-bottom: 10vw;
      }
    }
  }
}
.services-page-description {
  font-size: 1.1vw;
  line-height: 1.4;
  letter-spacing: 1.5px;
  margin-left: 50px;
  @include media-breakpoint-down(md) {
    font-size: 1.4vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 3.4vw;
    letter-spacing: 1px;
    margin: 0;
  }
}

// USED IN PROJECT DOWN
//COUNTER
#section-counter {
  position: relative;
  z-index: 0;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    z-index: -1;
    opacity: 0;
    background: #242423;
  }
}
.ftco-counter {
  .icon {
    span {
    }
  }
  .text {
    strong.number {
      font-weight: 400;
      font-size: 40px;
      color: $black;
      width: 50%;
      font-family: $font-secondary;
    }
    span {
      width: 50%;
      display: block;
      font-size: 1.3vw;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      @include media-breakpoint-down(md) {
        font-size: 1.55vw;
      }
      @include media-breakpoint-down(sm) {
        font-size: 3.5vw;
      }
    }
  }
  .counter-wrap {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }
}
// USED IN PROJECT UP
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  overflow: hidden;
}

//WORK
.project {
  position: relative;
  width: 100%;
  .img {
    position: relative;
    margin-bottom: 10vw;
    height: 50vw;
    width: 100%;
    background-size: cover;
    .text {
      width: 97.5%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // @include media-breakpoint-up(lg){
      margin-top: -3vw;
      // }
      h2 {
        text-transform: uppercase;
        font-size: 3vw !important;
        font-weight: 500 !important;
        line-height: 1 !important;
        @include transition(0.3s);
        a {
          color: black;
        }
      }
    }
    &:hover,
    &:focus {
      // border-top: solid $primary 3px;
      h2 {
        a {
          color: $primary;
          text-shadow: 10px 10px 70px $primary;
        }
      }
    }
  }
}

.btn-custom-2 {
  margin-top: -2vw;
  a {
    font-size: 1.8vw;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      font-size: 4vw;
    }
  }
  &:hover,
  &:focus {
    // border-top: solid $primary 3px;
    h2 {
      a {
        color: $primary;
        text-shadow: 10px 10px 70px $primary;
      }
    }
  }
}

//BLOG ENTRY
.blog-entry {
  margin-bottom: 5em;
  @include media-breakpoint-down(sm) {
    margin-bottom: 4em;
  }
  .img {
    display: block;
    height: 400px;
    width: 100%;
  }
  .text {
    position: relative;
    z-index: 0;
    width: 100%;
    h3 {
      font-weight: 500;
      font-size: 40px;
      // text-transform: uppercase;
      a {
        color: $black;
      }
    }
    .meta-wrap {
      width: 100%;
      display: block;
      .meta {
        span {
          font-size: 14px;
          margin: 0 10px 0 0;
          color: lighten($black, 50%);
          a {
            color: $black;
          }
        }
      }
    }
    .btn-custom {
      color: $black;
    }
  }
}

// USEFUL CODE
.aside-stretch {
  background: lighten($primary, 10%);
  &:after {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    content: "";
    width: 360%;
    background: lighten($primary, 10%);
    // background: #333644;
  }
  @include media-breakpoint-down(sm) {
    background: transparent;
    &:after {
      background: transparent;
      display: none;
    }
  }
}

.form-control {
  height: 52px !important;
  background: $white !important;
  color: $black !important;
  font-size: 18px;
  border-radius: 0px;
  box-shadow: none !important;
  &:focus,
  &:active {
    border-color: $black;
  }
}
textarea.form-control {
  height: inherit !important;
}

.ftco-animate {
  opacity: 0;
  visibility: hidden;
}

.about-author {
  img {
    @include border-radius(50%);
  }
  .desc {
    h3 {
      font-size: 20px;
    }
  }
  .bio {
  }
}

// USED IN PROJECT DOWN
.ftco-section {
  padding: 5vw 0;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 3em 0;
  }
}
.ftco-no-padding {
  padding: 0;
}
.ftco-extend-mb {
  margin-bottom: 8em;
}

.ftco-no-pt {
  padding-top: 0;
}
.ftco-no-pb {
  padding-bottom: 0;
}
// USED IN PROJECT UP

.ftco-section-3 {
  padding: 1.8em;
}

.ftco-footer {
  font-size: 14px;
  background: #242423;
  padding: 6em 0;
  .ftco-footer-logo {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .ftco-footer-widget {
    h2 {
      color: $white;
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 600;
    }
    ul {
      li {
        a {
          span {
            color: $primary;
          }
        }
      }
    }
    .btn-primary {
      background: $white !important;
      border: 2px solid $white !important;
      &:hover {
        background: $white;
        border: 2px solid $white !important;
      }
    }
  }
  ul.photo {
    width: 100%;
    li {
      width: 33.33%;
      height: 100px;
      float: left;
      a {
        width: 100%;
        height: 100px;
        display: block;
      }
    }
  }
  p {
    color: rgba($white, 0.5);
  }
  a {
    color: rgba($white, 0.5);
    &:hover {
      color: $white;
    }
  }
  .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: $black;
  }

  .categories {
    li {
      padding: 5px 0;
      a {
        color: rgba(255, 255, 255, 0.8) !important;
        span {
          color: $primary;
        }
      }
    }
  }
}

.footer-small-nav {
  > li {
    display: inline-block;
    a {
      margin: 0 10px 10px 0;
      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}
.media {
  .ftco-icon {
    width: 100px;
    span {
      color: $primary;
    }
  }
}

.ftco-social {
  padding: 0;
  li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;
    a {
      height: 50px;
      width: 50px;
      display: block;
      float: left;
      background: rgba(255, 255, 255, 1);
      border-radius: 50%;
      position: relative;
      span {
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $black;
      }
      &:hover {
        color: $white;
      }
    }
  }
}

// Map

#map {
  // height: 400px;
  width: 100%;
  @include media-breakpoint-down(md) {
    height: 300px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.4);
    box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba($primary, 0);
    box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0);
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

.heading-section {
  .subheading {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 24px;
    font-weight: 900;
    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }
  &.heading-section-white {
    .subheading {
      color: rgba(255, 255, 255, 0.9);
    }
    h2 {
      font-size: 40px;
      color: $white;
    }
    p {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  &.heading-section-2 {
    h2 {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
}

//COVER BG
.author-img,
.hero-wrap,
.img,
.blog-img,
.insta-img,
.user-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-light {
  background: #f8f9fa !important;
}

.bg-primary {
  background: $primary !important;
}

// magnific pop up

.image-popup {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.block-18 {
  width: 100%;
}

.block-27 {
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-bottom: 4px;
      font-weight: 400;
      a,
      span {
        border: 1px solid $black;
        text-align: center;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        color: $black;
      }
      &.active {
        a,
        span {
          background: $black;
          color: $white;
          border: 1px solid transparent;
        }
      }
    }
  }
}

//### .block-21
.block-21 {
  .blog-img {
    display: block;
    height: 80px;
    width: 80px;
  }
  .text {
    width: calc(100% - 100px);
    .heading {
      font-size: 16px;
      a {
        color: $black;
        &:hover,
        &:active,
        &:focus {
          color: $primary;
        }
      }
    }
    .meta {
      > div {
        display: inline-block;
        font-size: 12px;
        margin-right: 5px;
        a {
          color: lighten($black, 50%);
        }
      }
    }
  }
}

.tagcloud {
  padding: 0;
  a {
    text-transform: uppercase;
    display: inline-block;
    padding: 4px 10px;
    margin-bottom: 7px;
    margin-right: 4px;
    border-radius: 4px;
    color: $black;
    border: 1px solid #ccc;
    font-size: 11px;
    &:hover {
      border: 1px solid #000;
    }
  }
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
  .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%;
  }
  li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none;
    .vcard {
      width: 80px;
      float: left;
      img {
        width: 50px;
        border-radius: 50%;
      }
    }
    .comment-body {
      float: right;
      width: calc(100% - 80px);
      h3 {
        font-size: 20px;
      }
      .meta {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.1em;
        color: $black;
        margin-bottom: 20px;
      }
      .reply {
        padding: 5px 10px;
        background: lighten($black, 90%);
        color: $black;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        font-weight: 400;
        border-radius: 4px;
        &:hover {
          color: $white;
          background: lighten($black, 0%);
        }
      }
    }
  }
}

.search-form {
  .form-group {
    position: relative;
    margin-bottom: 0;
    input {
      padding-right: 50px;
      font-size: 14px;
      border: 1px solid $white;
      @include border-radius(30px);
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.subs-wrap {
  display: block;
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  z-index: 0;
  .img {
    position: relative;
    z-index: 0;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      opacity: 0.3;
      background: $black;
      z-index: -1;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 20px !important;
  }
  h3 {
    color: $white;
  }
}
.subscribe-form {
  .form-group {
    position: relative;
    margin-bottom: 0;
    @include border-radius(0);
    input {
      background: transparent !important;
      border: 1px solid transparent;
      color: rgba(255, 255, 255, 0.7) !important;
      font-size: 16px;
      @include border-radius(0);
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.7) !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.7) !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(255, 255, 255, 0.7) !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
    .form-control {
      border: 1px solid rgba(255, 255, 255, 0.2);
      font-weight: 500;
    }
    .submit {
      color: $black !important;
      display: block;
      width: 100%;
      height: 52px;
      font-size: 16px;
      background: $white !important;
      @include border-radius(0);
      &:hover {
        cursor: pointer;
      }
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8);
  }
}

// sidebar
.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  h3.sidebar-heading {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: 600;
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.categories,
.sidelink {
  padding: 0;
  li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    list-style: none;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }
    a {
      display: block;
      color: $black;
      span {
        position: absolute;
        right: 0;
        top: 0;
        color: $black;
      }
    }
    &.active {
      a {
        color: $black;
        font-style: italic;
      }
    }
  }
}

#ftco-loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  z-index: 1000;
}

#ftco-loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: none;
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
}

#ftco-loader.show {
  transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#ftco-loader .circular {
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  transform: rotate(0deg);
}

#ftco-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

//my css
.single-portfolio-item {
  position: relative;
  z-index: 1;
}

.project-description {
  margin-left: 2vw;
  margin-bottom: 5vw;
  .sub-title {
    font-size: 1.5vw;
    letter-spacing: 2px;
    @include media-breakpoint-down(md) {
      font-size: 3vw;
    }
  }
  .detail {
    font-size: 1vw;
    letter-spacing: 2px;
    @include media-breakpoint-down(md) {
      font-size: 2vw;
    }
  }
}

//who we are cards
.card__title {
  font-size: 0.8vw;
  margin: 0 0 0.3em;
  color: #6a515e;
  letter-spacing: 0.5px;
  line-height: 0.5;
  @include media-breakpoint-down(lg) {
    font-size: 1.2vw;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.2vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.6vw;
  }
}
.card__status {
  font-size: 0.8vw;
  color: #ccb78f;

  line-height: 1;
  @include media-breakpoint-up(xl) {
    font-size: 0.7vw;
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.05vw;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.15vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.5vw;
  }
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  font-size: 0.7vw;
  color: grey;
  font-family: "montserrat";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    font-size: 0.65vw;
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.05vw;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.15vw;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.5vw;
  }
}

div.gallery {
  margin: 5px;
  float: left;
  display: flex;
  align-items: center;
  width: 25vw;
  @include media-breakpoint-down(md) {
    width: 35vw;
  }
  @include media-breakpoint-down(sm) {
    width: 45vw;
  }
}
//who we are cards

.client-and-partners {
  h1 {
    font-size: 4vw;
    color: grey;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    margin: 4vw 0 7vw 0;
    @include media-breakpoint-down(md) {
      font-size: 5vw;
      margin: 0 0 10vw 0;
    }
    @include media-breakpoint-down(sm) {
      font-size: 7vw;
      margin: -8vw 0 15vw 0;
    }
  }
}

.landing-slider {
  div {
    h3 {
      font-size: 15px;
    }
  }
}

// MUI Input TextField
// input.MuiFilledInput-input.MuiInputBase-input.css-10botns-MuiInputBase-input-MuiFilledInput-input {
//   all: none;
//   padding-top: 40px;
//   padding-left: 20px;
//   margin-bottom: 10px;
//   border: none !important;
//   text-transform: none !important;
// }
.muiTextField > * {
  all: none;
  border: none !important;
  text-transform: none !important;
  // transform: translate(40px, 18px);
}
.muiTextField {
  all: none;
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 0;
  border: none !important;
  text-transform: none !important;
}

.muiTextArea > * {
  text-transform: none !important;
}
