@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

.category-title {
  display: flex;
  flex-direction: column;
  border-top: 2px ridge #ccb78f;
  border-bottom: 3px double #ccb78f;
  // border-radius: 11111em/999em;
  padding: 5px 0 0px 0;
  font-family: "josefin sans", Arial, sans-serif;
  font-size: 2rem;

  color: black;
}

.card__project12-list {
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.card__project12 {
  margin: 30px auto;
  width: 400px;
  height: 200px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.4s;
}

.card__project12 .card__project12_image {
  width: inherit;
  height: inherit;
  border-radius: 15px;
  font-size: 20px;
  color: gray;
  backdrop-filter: blur(80px); /* Chrome and Opera */
  box-shadow: inset 0 0 0 100px rgba(2, 2, 1, 0.08);
}

.card__project12 .card__project12_image img {
  width: inherit;
  height: inherit;
  border-radius: 15px;
  object-fit: cover;
}

.card__project12:hover {
  transform: scale(0.96, 0.96);
  box-shadow: 2px 14px 38px -2px rgba(0, 0, 0, 0.34);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

@media all and (max-width: 500px) {
  .card__project12-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
  .category-title {
    font-size: 1.7rem;
  }
  .card__project12 {
    width: 100%;
    height: 200px;
  }
}

/*
.card__project12 {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  transition: 0.4s;
}
*/
