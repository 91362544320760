.hero-section {
  align-items: flex-start;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
  --background-dark: #0f4667;
  --text-light: rgba(255, 255, 255, 0.6);
  --text-lighter: rgba(255, 255, 255, 0.9);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}

.card__test__two-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  gap: 3em;
}

@media (min-width: 540px) {
  .card__test__two-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .card__test__two-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card__test__two {
  list-style: none;
  position: relative;
  cursor: pointer;
}

.card__test__two:before {
  content: "";

  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__test__two__background {
  background-size: cover;
  background-position: center;
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85) grayscale(100);
  left: 0;
  position: absolute;
  right: 0;
  border-radius: 10px;
  top: 0;
  transform-origin: center;
  transform: scale(1) translateZ(0);
  transition: filter 200ms linear, transform 200ms linear;
  box-shadow: 23px 137px 172px -2px rgba(0, 0, 0, 0.66) inset;
  -webkit-box-shadow: 23px 137px 172px -2px rgba(0, 0, 0, 0.66) inset;
  -moz-box-shadow: 23px 137px 172px -2px rgba(0, 0, 0, 0.66) inset;
}

.card__test__two:hover .card__test__two__background {
  transform: scale(1.05) translateZ(0);
  filter: grayscale(0) !important;
}

// .card__test__two-grid:hover
//   > .card__test__two:not(:hover)
//   .card__test__two__background {
//   filter: brightness(0.5) saturate(0) contrast(1.2) blur(5px);
// }

//te4st code

.card__test__three .card__test__three__background {
  transform: scale(1.05) translateZ(0);
  filter: grayscale(0) !important;
}

// .card__test__two-grid
//   > .card__test__three:not()
//   .card__test__three__background {
//   filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
// }

//test code

.card__test__two__content {
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.card__test__two__category {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__test__two__heading {
  color: var(--text-lighter);
  font-size: 1.7em;

  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}

@media (max-width: 540px) {
  .card__test__two__heading {
    font-size: 1.3em;
  }
}
